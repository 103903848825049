import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import { withRouter, GLGlobal, GLUtil, connect, StateType, InvitationType } from "gl-commonui";
import { Row } from 'antd-min';
import { lazyInject, TYPES, RoleNameText } from '../../../util/index';
import { SchoolLocale } from '../../../locales/localeid';
import { getInfoByCode, consumeCode } from '../../../states/school/inviting';

interface InvitedPageProps extends RouteComponentProps<any> {
    getInfoByCode: (params, callback) => void
    consumeCode: (params) => void
    successText: string
}
interface InvitationSuccessPageStates {
    role?: string
    reference?: string
    schoolId?: string
}

@withRouter
@connect(({ inviting: { successText }, oidc: { loginInfo } }: StateType) => ({
    successText,
    isSignin: loginInfo && loginInfo.loggedin
}), {
        getInfoByCode,
        consumeCode
    })
export class InvitedPage extends Component<InvitedPageProps, InvitationSuccessPageStates> {
    invitationCode = GLUtil.queryParse().code
    email = GLUtil.queryParse().email
    constructor(props) {
        super(props);
        this.state = {}
    }
    
    componentDidMount() {
        this.props.getInfoByCode({ invitationCode: this.invitationCode }, this.callback.bind(this));
    }

    callback(data) {
        let role = RoleNameText.SchoolAdmin;
        if (data.invitationType == InvitationType.classTeacher ||
            data.invitationType == InvitationType.subTeacher ||
            data.invitationType == InvitationType.schoolTeacher) {
            role = RoleNameText.Teacher;
        } else if (data.invitationType == InvitationType.campusAdmin){
            role = RoleNameText.CampusAdmin;
        } else if (data.invitationType == InvitationType.accountManager){
            role = RoleNameText.AccountManager;
        }
        this.setState({
            reference: data.referenceResourceName,
            schoolId: data.referenceSchoolId,
            role: role
        });
    }

    success() {
        return <>
            <Row className='invitationsubject'>{GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationSuccessTitle })}</Row>
            <Row className='invitationtitle'>
                {GLGlobal.intl.formatMessage({ id: SchoolLocale.InvitationSuccess }, { email: this.email, role: this.state.role, reference: this.state.reference })}
            </Row>
        </>;
    }

    render() {
        return <Row>
            { this.success() }
        </Row>
    }
}
